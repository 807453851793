.contact_section{
    padding: 80px 0;
    background: url(/public/assets/contact.jpg);
    background-position: center center;
    background-size: cover;
}
/* Contact Page */

.img_box{
    padding: 1.5rem;
    background: rgba(44, 44, 44, 0.5);
    backdrop-filter: blur(10px);
    margin: 40px 0;
}
.contact_form .btn{
    visibility: visible;
}
.contact_heading{
    font-size: 25px;
    text-transform: uppercase;
    font-weight: 700;
    color: #fefefe;
}
.form-control{
    padding: 12px;
    font-size: 13px;
}
.form-label{
    font-size: 13px;
    margin-bottom: 0;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #fff;
    font-weight: 200;
}
.form-select{
    font-size: 13px;
}