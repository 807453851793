.accordion-button:focus{
    border: none;
    background: rgb(255, 47, 47);
    box-shadow: none;
    color: #fff;
}
.accordion-button:not(.collapsed){
background: rgb(255, 47, 47);
color: #fff;
}
.accordion-item{
    border: none;
    background: transparent;
}
.accordion-button:not(.collapsed)::after{
    background-image: url(../../public/assets/images/close-02.png);
}
.accordion-body p{
    margin-bottom: 5px;
    font-size: 13px;
    color: #fff;
}
.accordion-body h6{
    color: #fff;
}
.accordion-body{
    background:transparent;
    backdrop-filter: blur(4px);
}
