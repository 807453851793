*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}


body{
    background: rgb(218, 218, 218);
}



.header_nav{
    background: rgba(40, 21, 74, 0.5);
    backdrop-filter: blur(10px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding:.8rem;
    position: fixed;
    top: 0;
    z-index: 99;
}
.header_nav li{
    list-style: none;
}
.portfolio-container{
    position: relative; 
    height: 1026px;
}
.portfolio-item{
    position: absolute; 
    left: 0px; 
    top: 0px;
}
.nav_bar{
    display: grid;
    grid-template-columns: repeat(5,auto);
    grid-gap: 10px;
    
}
.nav_bar li a{
    text-decoration: none;
    padding: 10px;
    display: block;
    width: 100%;
    height: 100%;
    color: rgb(255, 255, 255);
    font-weight: 200;
}

.nav_bar li a:hover{
    background: #ff2f2f;
    color: #fff;
    border-radius: .5rem;
}
.logo_box li a img{
    width: 60px;
}
.btn{
    visibility: hidden;

}
.btn:hover{
    color: #fff;
}
.close-btn{
    display: none;
}



@media screen and (max-width:860px) {
    .nav_bar{
        position: absolute;
        top: 0;
        left: -100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100vh;
        background: #ff5c4e;
        z-index: 999;
        backdrop-filter: blur(6px);
        transition: 1s ease-in-out;
        padding-top: 7rem;
    }
    .manuResponsive{
        left: 0;
    }
    .nav_bar li{
        width: 100%;
    }
    .bi-list{
        z-index: 1000;
    }

    .close-btn{
        position: absolute;
        top: 1.5rem;
        right: 2.5rem;
        display: initial;
        color: #fff;
    }
    .close-btn:focus{
        box-shadow: none;
    }
    .btn{
        visibility: visible;
    }
    
}


/* Contact Page */
/* .contact_section{
    display: grid;
    align-items: center;
    background: url(/public/assets/contact.jpg);
    background-position: center center;
    background-size: cover;
    height: 100vh;
}
.img_box{
    padding: 1.5rem;
    background: rgba(255, 255, 255, .5);
    backdrop-filter: blur(10px);

    margin-top: 5rem;
}
.contact_form .btn{
    visibility: visible;
}
.contact_heading{
    padding-block: 3rem;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: 700;
    color: #e63434;
} */
