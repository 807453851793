.swiper_img{
    width: 100%;
}
.slogan_box{
    position: absolute;
    top: 50%;
    left: 5%;
}
.slogan_box h1{
    color: #fff;
    font-size: 35px;
    text-transform: capitalize;
    font-weight: 700;
    

}
.slogan_box h1 span{
    color: rgb(235, 78, 26);
    font-size: 40px;
    font-weight: 300;
}
.slogan_box p{
    color: rgb(255, 255, 255);
    font-size:19px;
}

.loader {
    margin-left: 24px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


@media screen and (max-width:567px) {
    .swiper_img{
        height: 50vh;
    }
    .slogan_box h1{
        font-size: 22px;
    }
    .slogan_box h1 span{
        font-size: 27px;
    }
    .slogan_box p{
        font-size: 11px;
    }
    .swiper-button-prev:after,.swiper-button-next:after{
        font-size: 22px !important;
    }
    
}
