.modal_wrapper{
    position: fixed;
    width: 100%;
    background: #04050659;
    backdrop-filter: blur(6px);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;


}
.modal_box{
    width: 50%;
    background: #7b78783d;;
    border-radius: 15px;
    backdrop-filter: blur(3px);

}
.modal_btn{
    position: absolute;
    top: 6%;
    right: 3%;
    transform: translate(-30% , -26%);
    background: transparent;
    outline: none;
    border: none;

}
.modal_btn svg{
    color: #fff;
}
.modal_header{
    margin-top: 30px;
    font-size: 25px;
    padding-left: 20px;
    color: #c7c7c7;
}
.modal_form{
    padding: 15px;
    background: transparent;
}
.modal_contact_btn{
    border: none;
    outline: none;
    background: rgb(255, 75, 75);
    padding: 8px 15px;
    font-size: 15px;
    color: #fff;
}

@media screen and (max-width:576px) {
    .modal_box{
        width: 90%;
        
    
    }
    
}