.service{

    background: #000;
}
.service_first{
    padding-top: 80px;
}

.md_service_bg{
    background: #313131;
}
.service_text{
    color: #fff;
}
.service_text p{
    margin-bottom: 0;
    font-weight: 200;
    font-size: 15px;
}
.img_box_service{
    padding: 30px;
}
.img_box_service img{
    animation: moving 5s linear infinite;
}