.section{
    /* margin-top: 5rem; */
}
.home_heading{
    text-align: center;
    padding-top: 30px;
    font-size: 30px;
    font-weight: 600;
    position: relative;
    padding-bottom: 10px;
    color: #fff;
}
.home_heading::after{
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #e03a3c;
    bottom: 0;
    left: calc(50% - 25px);

}
.weProvide{
    background: #000000;
    padding: 100px 0;
    position: relative;
    background-image: url(../../public/assets/images/bg_all-01.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.icon_box_info{
    padding: 25px;
    text-align: center;
    background:linear-gradient(45deg, #4e8f26, #106d3a);
    position: relative;
    height: 300px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    border-radius: 15px;
    margin-top: 35px;
    animation: background 5s linear infinite;
}
@keyframes background {
    0%{
        background:linear-gradient(45deg, #4e8f26, #106d3a);
    }
    50%{
        background: #4e8f26;
        
    }
    100%{
        background:#ff2a2e;
        
    }
    
}
.circle{
    position: absolute;
    width: 100%;
    height: 100%;

}
.circle img{
    width: 150px;
    position: absolute;
    left: 10%;
    top:3%;
    animation: moving 5s linear infinite;
}
@keyframes moving {
    0%{
        transform: translateY(0);
    }
    50%{
        transform: translateY(30px);
    }
    100%{
        transform: translateY(0 );
    }
    
}
.weProvide .circle img:nth-child(2){
    top: 45%;
    left: 50%;

}
.icon_box_info svg{
    color: #fff;
    font-size: 35px;
    margin-bottom: 15px;
}
.icon_box_info h4{
    color: #ffbf2a;
}
.icon_box_info .icon_box_info_para p{
    color: #fff;

}
.icon_box_info:hover .icon_box_info_para{
    visibility: visible;
}
.icon_box_info .icon_box_info_para{
    position: absolute;
    top: 0;
    left: 0;
    background: #e03a3c;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 25px;
    visibility: hidden;
}


/*/////////////////////////////
counts Part/////////////////////////////
*/
#counts{
    padding: 80px 0;
    background: #000;
    position: relative;
}
.count-box{
    background: rgb(126 126 126 / 34%);
    padding: 30px 15px;
    text-align: center;
    border-radius: 5px;
    position: relative;
    backdrop-filter: blur(6px);
}
.count-box svg{
    position: absolute;
    
    top: -27px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 54px;
    background: #fff;
    color: #e03a3c;
    border-radius: 50px;
    border: 2px solid #fff;
    box-shadow: 0px 2px 25px rgba(0, 0, 0, 0.1);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 8px;
}
.counts .count-box span {
    font-size: 36px;
    display: block;
    font-weight: 700;
    color: #f5eed6;
}
.counts .count-box p{
    color: #fff;
}
.line_move{
    position: absolute;
    width: 150px;
    top: 50%;
    left: 0;
    transform: translate(-50%);
    animation: lineMove linear 5s infinite;
}
@keyframes lineMove {
    0%{
        transform: translate(0);
    }
    50%{
        transform: translate(1200px) rotate(360deg);
    }
}
.line_move img{
    width: 100%;
}
@media screen and (max-width:576px) {
    /* #counts{
        background-image: url(../../public/assets/images/5338635-02.png);
    } */
}

.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    text-align: center;
    color: #fff;
}
.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #e03a3c;
    bottom: 0;
    left: calc(50% - 25px);
}
.section-bg{
    padding: 50px 0;
    color: #fff;
    background: #000000;
    background-image: url(../../public/assets/images/bg_all-01.png);
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;


}
.services .icon-box {
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 6px;
    background: rgba(53, 53, 53, 0.644);
    transition: 0.3s;
    backdrop-filter: blur(4px);
}
.services .icon-box svg {
    float: left;
    color: #e03a3c;
    font-size: 40px;
    line-height: 0;
}
.text_box{
    margin-left: 60px;
    position: relative;
}
.text_box h4 a{
    color: #fff;
    text-decoration: none;
    font-size: 18px;
}
.services .icon-box p {
    font-size: 12px;
}


.portfolio{
    background: #000;
    padding: 80px 0;
    position: relative;
}
.portfolio .nav{
    justify-content: center;
}
.portfolio .nav-tabs{
    border-bottom: none;
}
.portfolio .nav-tabs .nav-link{
    border: none;
    color: #ff2a2e;
}
.portfolio .nav-tabs .nav-link.active{
    background: #e03a3c;
    color: #fff;
    border-bottom: 2px solid #fff;
}
.portfolio .nav-tabs .nav-link.active:hover{
    border-bottom: 2px solid #fff;
}
.portfolio .nav-tabs .nav-link:hover{
    border: none;
}
.portfolio .tab-pane{
    padding-top: 50px;
}
.portfolio .tab_box{
    margin-bottom: 20px;
    position: relative;
}
.faQs{
    padding: 80px 0;
    background: #000;
    background-image: url(../../public/assets/images/bg_all-01.png);
    background-position: center;
    background-size: cover;
}
.tab_box img{
    cursor: pointer;
}
.tab_box:hover .portfolio-info{
    visibility: visible;
    width: 100%;
    transform: scale(1);
}
.tab_box .portfolio-info{
    position: absolute;
    background: #000000b1;
    width: 0%;
    height: 100%;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 20px;
    visibility: hidden;
    cursor: pointer;
    transform: scale(0);
    transition: .5s ease-in-out;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
.tab_box .portfolio-info h4,.tab_box .portfolio-info p{
    color: #fff;
}
.portfolio-info .portfolio-links a{
    color: #ffffffa2;
    font-size: 25px;
}

/*//////////////////////////////////
contact  //////////////////////////////////
*/
.contact{
    padding: 80px 0;
    background-image: url(../../public/assets/images/bg_all2-01.png);
    background-position: center center;
    background-size: cover;
    background-color: #000;
}
.contact .info-box {
    color: #444444;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px 0 32px 0;
    border-radius: 4px;
    background: #0000006b;
    backdrop-filter:blur(4px);
}
.contact .info-box svg {
    font-size: 50px;
    color: #e03a3c;
    border-radius: 50%;
    padding: 8px;
    border: 2px dotted #f8d4d5;
}
.contact .info-box h3{
    color: #fff;
    font-size: 20px;
    margin-top: 20px;
    font-weight: 200;
}
.contact .info-box a{
    color: #fff;
    font-weight: 200;
    letter-spacing: .3px;
    font-size: 13px;
    text-decoration: none;
}
.contact .contact_us_form {
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.6);
    padding: 30px;
    border-radius: 4px;
    background: #00000063;
    backdrop-filter:blur(4px);
}
.contact .contact_us_form .form-group {
    margin-bottom: 25px;
}
.contact .contact_us_form button[type=submit] {
    background: #e03a3c;
    border: 0;
    padding: 10px 32px;
    color: #fff;
    transition: 0.4s;
    border-radius: 4px;
}
.contact .contact_us_form .btn_box{
    margin-top: 36px;
}

/*//////////////////////////////
logoPart  //////////////////////////////
*/

.logo_part{
    padding: 80px 0;
    background: #c4c4c4;
}
.logo_part .swiper{
    padding: 30px 0;
}
.logo_part .swiper-button-prev:after,.logo_part .swiper-button-next:after{
    background: #e03a3c;
    padding: 10px;
    font-size: 20px;
    color: #fff;
}
.logo_part .swiper-button-prev{
    left: 0;
}
.logo_part .swiper-button-next{
    right: 0;
}
.logo_part .swiper-pagination-bullet{
    background: #ff373ada;
}

/*/////////////////////////////////
testimonial  /////////////////////////////////
*/

.testimonial{
    padding: 80px 0;
    background: #000;
}
.testimonial .section-title p{
    color: #fff;
    font-weight: 200;
}
.testimonial .testimonial-item {
    box-sizing: content-box;
    padding: 30px;
    min-height: 200px;
    box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
    position: relative;
    background: #fff;
    border-radius: 15px;
}
.testimonial .testimonial-item .testimonial-img {
    width: 90px;
    border-radius: 10px;
    border: 6px solid #fff;
    float: left;
    margin: 0 10px 0 0;
}
.testimonial .testimonial-item h3 {
    font-size: 18px;
    font-weight: bold;
    margin: 25px 0 5px 0;
    color: #111;
}
.testimonial .testimonial-item h4 {
    font-size: 14px;
    color: #999;
    margin: 0;
}
.testimonial .testimonial-item p {
    font-style: italic;
    margin: 30px auto 15px auto;
    padding: 10px;
    font-size: 13px ;
}
.testimonial .testimonial-item svg {
    display: inline-block;
    position: relative;
    color: #f8d4d5;
    padding: 2px;
    font-size: 30px;
}

.testimonial .swiper-pagination-bullet{
    background: #ff373ada;
}
.testimonial .swiper-wrapper{
    padding: 50px 0;
}
@media screen and (max-width:1080px) {
    @keyframes lineMove{
        50% {
            transform: translate(846px) rotate(360deg);
        }

    }
}
@media screen and (max-width:992px) {
    @keyframes lineMove{
        50% {
            transform: translate(617px) rotate(360deg);
        }

    }
    
}


@media screen and (max-width:576px) {
    @keyframes lineMove {
        0%{
            transform: translate(0);
        }
        50%{
            transform: translate(0px) rotate(0deg);
        }
    }
    .contact,.faQs,.testimonial,.logo_part,.portfolio,.section-bg,#counts{
        padding: 0 0;
    }
    .section-title h2{
        font-size: 23px !important;
    }
    .modal_contact_btn{
        padding: 8px 7px;
        font-size: 11px;
    }
    .logo_part .swiper-button-prev:after, .logo_part .swiper-button-next:after{
        padding: 4px;
        font-size: 13px !important;
    }
    .footer .footer-top .footer-contact h3 a img {
        width: 82px;
    }
    .footer .footer-top .footer-contact p{
        font-size: 10px;
        margin-bottom: 10px;
    }
    
}

.loader {
    margin-left: 24px;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #3498db; /* Blue */
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
}
  
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}