.footer{
  background: #1b1b1b;
}
.footer .footer-top{
  padding-top: 50px;
}
.footer .footer-top .footer-contact h3 a img {
  width: 120px;
}
.footer .footer-top .footer-contact p {
  font-size: 12px;
  margin-bottom: 0;
  color: #fff;
  font-weight: 200;
}

.footer .footer-top h4 {
  font-size: 16px;
  font-weight: 500;
  position: relative;
  padding-bottom: 12px;
  color: #fff;
}
.footer .footer-top h4::after {
  content: "";
  position: absolute;
  display: block;
  width: 20px;
  height: 2px;
  background: #e03a3c;
  bottom: 0;
  left: 0;
}
.footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.footer .footer-top .footer-links ul a {
  color: #aaaaaa;
  transition: 0.3s;
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  font-size: 13px;
}
.footer .footer-top .footer-links ul a:hover{
  color: #fff;
}
.footer-links svg{
  color: #fff;
}
.footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 5px 10px;
  position: relative;
  border-radius: 4px;
  text-align: left;
}
.footer .footer-newsletter form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 80px);
}
.footer .footer-newsletter form input[type=submit] {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  border: 0;
  background: none;
  font-size: 14px;
  padding: 0 11px;
  background: #e03a3c;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}
.form-control:focus{
  box-shadow: none;
}
.form-control{
  font-size: 13px;
}
.footer .footer-newsletter p{
  color: #fff;
  font-size: 13px;
}
.footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #2b2b2b;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 4px;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
.footer .social-links a:hover{
  background: #e03a3c;
}
.copyright{
  color: #fff;
  font-size: 13px;
  font-weight: 200;
}
.credits{
  color: #fff;
  font-size: 13px;
}
.credits a{
  text-decoration: none;
  color: #e03a3c;
}