.about{
    padding: 80px 0;
    background: #000;
    background-image: url(../../public/assets/images/bg_all-01.png);
    background-position: center center;
    background-size: cover;
}


.section-title h2 {
    font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 20px;
    position: relative;
    text-align: center;
    color: #fff;
    padding-top: 50px;
}
.section-title h2::after {
    content: "";
    position: absolute;
    display: block;
    width: 50px;
    height: 3px;
    background: #e03a3c;
    bottom: 0;
    left: calc(50% - 25px);
}
.about_para{
    margin-top: 20px;
}
.about_para p{
    color: rgb(225, 225, 225);
    line-height: 1.8;
    font-size: 14px;
}
.team_img_box {
    position: relative;
    animation: imgPassing 8s linear infinite;
    height: 24rem;
    /* width: 50%; */
    background-position: center;
    background-size: cover;
    overflow: hidden;
    border-radius: 15px;
}

@keyframes imgPassing {
    0%{
        background-image: url(../../public/assets/images/team-01.png);
       
    }
    50%{
        background: url(../../public/assets/images/team-1-01.png);
        
    }
    100%{
        background-image: url(../../public/assets/images/team-3-01.png);
    }
    
}
.vision_box{
    padding: 80px 0;
    background: #000;
    /* background-image: url(../../public/assets/images/about_vision-01.png);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; */
}
.visionDetailSBox{
    padding: 20px;
    text-align: center;
    background: #46464678;
    border-radius: 10px;
    backdrop-filter: blur(3px);
    margin-bottom: 30px;
}
.topVsBox{
    margin-top: -40px;
    display: flex;
    justify-content: center;
}
.topVsBox h2{
    background: #fff;
    width: 40%;
    border-radius: 10px;
    font-size: 25px;
    padding: 5px 15px;
    font-weight: 600;
    color: #e03a3c;
    box-shadow: 1px 3px 5px rgb(255, 88, 88,0.5);
}
.visionDetailSBox p{
    color: #fff4f4;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 200;
}


@media screen and (max-width:992px) {
    .visionDetailSBox{
        margin-bottom: 40px;
    }
    
}